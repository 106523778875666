.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    row-gap: 25px;
    background-color: rgba(249, 250, 251, 255);
  }

  .text-container {
    margin: 24px 0 16px;
    text-align: center;
    display: flex;
  }
  
  .form-title {
    font-size: 28px;
    font-weight: 300;
    font-family: "Akrobat Bold";
    color: #111827;
    margin: 0;
    text-align: center;
  }
  
  .form-text {
    margin: 0;
    font-weight: 500;
    font-size: 20px;
    padding: 15px;
    text-align: center;
    color: #6B7280;
  }
  
  .anchor {
     color: #0C4076;
  }

  .paragr1{
    padding-bottom: 20px;
  }
  .paragr1 > p{
    margin: 3px 0px;
  }

  .paragr2{
    padding-bottom: 20px;
  }
  .paragr2 > p{
    margin: 3px 0px;
  }