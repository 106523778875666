.hamburger {
  display: flex;
}

.button {
  display: flex;
}

.button:hover {
  cursor: pointer;
}

.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  background: white;
  position: absolute;
  width: 100%;
  height: calc(100vh - 30px);
  flex-direction: column;
  top: 42px;
  left: 0px;
  animation: move 0.3s ease-out;
}

.children {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: fit-content;
}

.children :nth-child(n) {
  text-align: center;
  padding: 16px;
  width: 100%;
}

.children :nth-child(n + 2) {
  border-top: 2px solid black;
}

@keyframes move {
  0% {
    left: -200vw;
  }
  100% {
    left: 0px;
  }
}
