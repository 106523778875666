.container {
  display: flex;
  min-height: 100vh;
}

.left {
  width: 41.5%;
  float: left;
  padding: 80px 24px 24px 24px;
}

.right {
  width: 58.5%;
  float: right;
  background: url("../../../assets/images/bgmaraton.jpg") no-repeat;
  background-size: cover;
}

.toolbar {
  max-width: 500px;
  margin: auto;
}

.form {
  display: flex;
  min-height: 80vh;
  justify-content: center;
  align-self: center;
}

@media (max-width: 768px) {
  .right {
    display: none;
  }

  .left {
    min-height: 100vh;
    width: 100%;
    float: none;
    padding: 80px 24px 24px 24px;
  }

  .container {
    min-height: 100vh;
    display: block;
    align-items: center;
    justify-content: center;
  }
}