.top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 24px;
}
.main {
  background-color: transparent;
  padding: 24px;
  border: 1px solid rgba(229, 231, 235, 1);
  border-radius: 15px;
  z-index: 5;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}
.title {
    text-align: left;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #111827;
    line-height: 30px;
    margin-top: 0;
    margin-bottom: 24px;
  }


.description {
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: #6b7280;
  margin-bottom: 6px;
}

.data {
  display: flex;
  flex: 2;
}

.data > * {
  height: inherit;
  width: 33.33%;
}

.data > div:nth-child(2) {
  margin-left: 24px;
  margin-right: 24px;
}
.data > div:nth-child(3) {
  margin-right: 24px;
}

@media (max-width: 767px) {
  .data {
    flex-direction: column;
    min-width: 100%;
    width: 100%;
  }

  .data > div {
    width: 100%;
    margin: 0;
  }

  .data > div:nth-child(2) {
    margin: 0;
  }
}
