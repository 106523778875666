.closeIcon {
  position: absolute;
  top: -40px;
  right: 0px;
  cursor: pointer;
  width: fit-content;
  height: fit-content;
}

.slip {
  width: 80vw;
  max-width: 1242px;
}