.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
}

.right {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.logo {
  width: 118px;
  height: auto;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.links {
  display: flex;
  height: 1rem;
  line-height: 1rem;
}

.link {
  font-weight: 600;
  color: black;
  line-height: 1rem;
}

@media (min-width: 0px) {
  .burger {
    display: flex;
  }

  .links {
    display: none;
  }
}

@media (min-width: 769px) {
  .links {
    display: flex;
  }

  .link {
    font-size: 18px;
    padding: 0px 15px;
  }

  .links :nth-child(n+2) {
    border-left: solid black 1.5px;
  }
}
