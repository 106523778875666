.baton {
  border: none;
  gap: 4px;
  border-radius: 4px;
  width: fit-content;
  height: 56px;
  min-width: 160px;
  font-size: 20px;
  line-height: 25px;
  cursor: pointer;
  transition: all 0.2s;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  border-radius: 12px;
}
.icon {
  min-width: 40px;
  height: 40px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 1);
}
.icon:hover {
  background-color: rgba(243, 244, 246, 1);
}
.primary {
  color: #ffffff;
  background: #1a9fd9 100%;
}
.baton.primary svg * {
  fill: #ffffff;
}
.primary:hover {
  background: #0c4076;
}
.primary:disabled {
  cursor: default;
  background: rgba(209, 236, 247, 1);
}
.cancel {
  background: #f3f4f6;
  color: #111827;
}
.baton.cancel svg * {
  fill: #111827;
}
.cancel:hover {
  background: #e5e7eb;
}
.cancel:disabled {
  cursor: default;
  color: #9ca3af;
  background: #f9fafb;
}
.baton.cancel:disabled svg * {
  fill: #9ca3af;
}
.destructive {
  color: #ffffff;
  background: rgba(224, 27, 27, 1);
}
.baton.destructive svg * {
  fill: #ffffff;
}
.destructive:hover {
  background: rgba(179, 22, 22, 1);
}
.destructive:disabled {
  cursor: default;
  background: rgba(249, 209, 209, 1);
}
.stretch {
  width: 100%;
}
