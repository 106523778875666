* {
  box-sizing: border-box;
}
.input-element {
  border: none;
  width: 100%;
  font-family: "Akrobat";
  background-color: transparent;
  position: relative;
  width: 100%;
  font-size: 20px;
  font-weight: 600;
  color: #111827;
  transition: all 0.2s;
  padding: 0;
}

.input-element:focus {
  outline: none;
}

.input-element::placeholder {
  font-weight: 500;
}

.main {
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  text-align: left;
  position: relative;
}

.label {
  display: block;
  font-size: 14px;
  min-height: 19px;
  color: #0c4076;
  transition: all 0.2s;
  font-weight: 500;
  overflow: hidden;
  opacity: 100;
}

.unvisible {
  opacity: 0;
}

.input {
  display: flex;
  justify-content: center;
  align-items: stretch;
  border: none;
  width: 100%;
  padding-bottom: 5px;
  border-bottom: 1px solid #9ca3af;
}

.input:focus-within {
  border-bottom: 1px solid rgba(26, 159, 217, 1);
}

.icon {
  margin: 0 5px;
  padding-bottom: 2px;
  display: flex;
  align-items: flex-end;
}

.input-container-error {
  font-size: 14px;
  min-height: 17px;
  margin-top: 8px;
}

.error {
  color: #e01b1b;
}

.error-border {
  border-bottom: 1px solid red;
}

.input-element::-webkit-outer-spin-button,
.input-element::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
