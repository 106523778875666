.main {
    background-color: white;
    padding: 24px;
    box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    z-index: 5;
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }
  
  .title {
    text-align: left;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #111827;
    line-height: 30px;
    margin-top: 0;
    margin-bottom: 24px;
  }
  
  .info {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
  }

  .error {
    color: #e01b1b;
  }

  .error-text {
      color: #e01b1b;
  }