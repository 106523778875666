@import url("https://fonts.googleapis.com/css?family=Montserrat");

.avatar {
  display: flex;
  border-radius: 50%;
  background-color: rgba(243, 244, 246, 1);
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.avatar.small {
  width: 40px;
  height: 40px;
}

.avatar.large {
  width: 64px;
  height: 64px;
}

.inner {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat";
  font-weight: 600;
}

.inner.small {
  font-size: 16px;
}

.inner.large {
  font-size: 24px;
}

.avatarOptionsContainer {
  position: absolute;
  z-index: 100;
  cursor: default;
  flex-direction: column;
  width: fit-content;
  height: fit-content;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 20px;
  margin: 0;
  box-shadow: 0px 5px 20px rgb(0, 0, 0, 0.1);
}

.avatarOptionsContainer.small {
  top: 45px;
  left: -100px;
}

.avatarOptionsContainer.large {
  top: 70px;
  left: -75px;
}

.avatarOption {
  display: flex;
  cursor: pointer;
  justify-content: flex-start;
  text-align: start;
  gap: 5px;
  border-radius: 15%;
  font-size: 20px;
  font-weight: 500;
  width: 100%;
  height: fit-content;
}

.avatarOption:hover {
  text-decoration: underline;
}
