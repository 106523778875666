.textData {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 21px;
  flex-basis: 100%;
}
p {
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: #6b7280;
}
.label {
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 1px;
}
.value {
  font-weight: 600;
  color: #111827;
}
