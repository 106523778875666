.top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 24px;
}

.title {
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  color: #111827;
  margin-bottom: 0;
  margin-top: 0;
}

.description {
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: #6b7280;
  margin-bottom: 6px;
}

.relay-info {
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  margin-bottom: 6px;
  color: #e01b1b;
}

.data {
  display: flex;
  flex: 2;
}

.data > * {
  height: inherit;
  width: 33.33%;
}

.data {
  gap: 24px;
}

@media (max-width: 767px) {
  .data {
    flex-direction: column;
    min-width: 100%;
    width: 100%;
  }

  .data > div {
    width: 100%;
    margin: 0;
  }

  .data > div:nth-child(2) {
    margin: 0;
  }
}
