.main {
  background-color: transparent;
  padding: 24px;
  border: 1px solid rgba(229, 231, 235, 1);
  border-radius: 15px;
  z-index: 5;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.title {
  text-align: left;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #111827;
  line-height: 30px;
  margin-top: 0;
  margin-bottom: 24px;
}

.info {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

.foreign-payment-container {
  display: flex;
  flex-direction: column;
  border: 1px solid #d1d5db;
  border-radius: 8px;
}

.foreign-payment-container div {
  padding: 10px;
}

.foreign-payment-container div:nth-child(2) {
  border-top: 1px solid #d1d5db;
  border-bottom: 1px solid #d1d5db;
}

.foreign-payment-container div p:first-child {
  font-weight: bold;
}

.total-sum {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #111827;
  margin: 24px 0 16px;
}

.already-registered-for-event {
  font-size: 20px;
  line-height: 25px;
  color: #6b7280;
}

.payment-slip-button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-top: -10px;
}

.payment-slip-button:hover svg path {
  fill: black;
}

.payment-slip-button > p {
  display: flex;
  font-size: 16px;
  line-height: 20px;
  color: #6b7280;
  margin-left: 10px;
}

.payment-slip-button > p:hover {
  text-decoration: underline;
}
