/* Customize the label (the container) */
.container {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  justify-content: flex-end;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
}
.text {
  align-self: center;
  width: 95%;
  margin: 0;
  padding: 0;
  text-align: left;
  color: #111827;
}
/* Hide the browser's default checkbox */
.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkbox input:disabled + label {
  color: rgba(17, 24, 39, 0.3);
}

/* Create a custom checkbox */
.checkmark {
  border: 2px solid rgba(156, 163, 175, 1);
  border-radius: 4px;
  width: 24px;
  height: 24px;
  background-color: whitesmoke;
  display: inline-block;
  position: relative;
  top: 5px;
  margin-right: 5px;
}

.checkmark.error {
  border: 2px solid #e01b1b;
}

/* When the checkbox is checked, add a blue background */
.checkbox input:checked + label .checkmark {
  background: rgba(26, 159, 217, 1);
}

.checkbox input:disabled:checked + label .checkmark {
  background: rgba(26, 159, 217, 0.3);
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox input:checked + label .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  position: relative;
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid #e5e5e5;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
