.toolbar {
  max-width: 1200px;
  margin: 0 auto;
  padding: 24px;
  width: 100%;
}

.main {
  background-color: #f9fafb;
}

.page-container {
  min-height: calc(100vh - 392px);
  max-width: 1200px;
  margin: 0 auto;
  padding: 24px;
  display: flex;
  flex-direction: column;
}

.top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 24px;
}

.toolbar Toolbar {
  display: none !important;
}

@media (max-width: 767px) {
  .main {
    margin: 0;
  }
}
