form {
  display: grid;
  align-items: center;
  justify-content: center;
  margin: 15px;
  max-width: 500px;
}

.page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 480px;
}

.form-title {
  font-size: 28px;
  font-weight: 300;
  font-family: "Akrobat Bold";
  text-align: center;
  color: #111827;
  margin: 0;
}

.form-text {
  margin: 0;
  color: #6b7280;
  font-family: "Akrobat";
  font-weight: 500;
  font-size: 20px;
  text-align: center;
}

.form-text-header {
  padding: 0;
  margin-bottom: 6px;
  flex-basis: 100%;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
}

.text-right {
  display: flex;
  justify-content: right;
  margin: 10px 0;
}

.text-right-sub {
  display: inline;
  padding: 0;
}

.form-text-hover:hover {
  color: #111827;
  text-decoration: underline;
  cursor: pointer;
}

.input-container {
  display: flex;
  align-items: center;
  align-self: center;
  margin: 5px;
  position: relative;
  max-width: 480px;
  width: 100%;
}

.button {
  width: 480px;
}

.arrow {
  color: #6b7280;
  margin-right: 2px;
}

.arrow:hover {
  color: #111827;
  text-decoration: underline;
}

.input-container-error {
  font-size: 14px;
  color: #e01b1b;
}

.icon {
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
}

.bottom-link {
  display: flex;
  justify-content: center;
}

@media (max-width: 1150px) {
  form {
    width: 100%;
    justify-content: center;
  }

  .form-text {
    margin: 15px;
    max-width: 350px;
  }

  .page-container {
    display: flex;
    justify-content: center;
  }

  .input-container {
    max-width: 350px;
    align-self: center;
  }

  .button {
    max-width: 350px;
    align-self: center;
  }

  .center-div {
    align-items: center;
    align-self: center;
  }
}

@media (max-width: 768px) {
  form {
    width: 100%;
    justify-content: center;
  }

  .form-text {
    margin: 15px;
    max-width: 600px;
  }

  .page-container {
    display: flex;
    justify-content: center;
  }

  .input-container {
    max-width: 500px;
    align-self: center;
  }

  .button {
    max-width: 500px;
    align-self: center;
  }

  .center-div {
    align-items: center;
    align-self: center;
  }
}

@media screen and (max-width: 500px) {
  form {
    width: 100%;
    margin: 0;
    justify-content: center;
  }

  .page-container {
    display: flex;
    justify-content: center;
  }

  .form-text {
    margin: 15px;
    max-width: 300px;
  }

  .page-container {
    display: flex;
    justify-content: center;
  }

  .input-container {
    max-width: 300px;
    align-self: center;
  }

  .button {
    max-width: 300px;
    align-self: center;
  }
}
