a {
  text-decoration: none;
  font-size: 20px;
  text-align: right;
  line-height: 24.98px;
  color: rgba(107, 114, 128, 1);
}

a:hover {
  color: rgba(17, 24, 39, 1);
  text-decoration: underline;
  text-decoration-thickness: 1px;
  cursor: pointer;
}

.disabled {
  color: rgba(209, 213, 219, 1);
  pointer-events: none;
}

.destructive {
  color: rgba(224, 27, 27, 1);
}

.destructive:hover {
  color: rgba(179, 22, 22, 1);
}

.destructive-disabled {
  color: rgba(236, 118, 118, 1);
  pointer-events: none;
}

.primary {
  color: rgba(12, 64, 118, 1);
}

.bold {
  color: #000;
  font-weight: 700;
}

.primary-small {
  color: rgba(12, 64, 118, 1);
  font-size: 16px;
}
