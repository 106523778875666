.container {
  height: 100vh;
}
.toolbar {
  max-width: 1200px;
  margin: 0 auto;
  padding: 24px;
  width: 100%;
}

.container {
  display: flex;
  height: 91vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: rgba(107, 114, 128, 1);
}

.title {
  font-size: 28px;
  font-weight: 300;
  font-family: "Akrobat Bold";
  color: #111827;
  margin: 0;
  align-self: center;
}

.text {
  margin: 0;
  font-weight: 500;
  font-size: 20px;
  padding: 15px;
}
