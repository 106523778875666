.register-form {
  display: grid;
  align-items: center;
  justify-content: center;
  margin: 20px 0px;
  max-width: 500px;
}

.page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 200px;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.form-title {
  font-size: 28px;
  font-weight: 300;
  font-family: "Akrobat Bold";
  color: #111827;
  margin: 0;
  text-align: center;
}

.form-text {
  margin: 0;
  color: #6b7280;
  font-weight: 500;
  font-size: 20px;
  padding: 15px;
  text-align: center;
}

.form-text-header {
  padding: 0;
  color: black;
  margin-bottom: 6px;
  flex-basis: 100%;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
}

.text-right {
  display: flex;
  justify-content: right;
  margin: 10px 0;
}

.text-right-sub {
  display: inline;
  padding: 0;
}

.form-text-hover:hover {
  color: #111827;
  text-decoration: underline;
  cursor: pointer;
}

.input-container {
  align-items: center;
  position: relative;
  max-width: 480px;
  width: 100%;
}

.input-field {
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  width: 50%;
}

.input-fields {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.button {
  width: 100%;
}

.checkbox-container {
  width: 100%;
  max-width: 480px;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 25px;
}

.checkbox-container > div > * {
  font-size: 16px;
}

.checkbox-container > div > * > p > a {
  font-size: 16px;
}

.input-container-error {
  font-size: 14px;
}

.error {
  color: #e01b1b;
}

.icon {
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
}

.email {
  color: rgba(75, 85, 99, 1);
  font-weight: 600;
}
.black {
  color: black;
}

.captcha {
  width: 100%;
}

@media (max-width: 768px) {
  form {
    width: 100%;
    justify-content: center;
  }

  .form-text {
    margin: 15px;
    max-width: 600px;
  }

  .page-container {
    display: flex;
    justify-content: center;
  }

  .content {
    display: flex;
    justify-content: center;
    height: 100vh;
  }

  .page-container {
    display: flex;
    justify-content: center;
  }

  .input-container {
    max-width: 500px;
    align-self: center;
  }

  .input-field {
    width: 100%;
  }

  .button {
    max-width: 500px;
    align-self: center;
  }

  .inputs-right,
  .inputs-left {
    width: 100%;
    float: none;
    margin: 0px;
  }

}

@media screen and (max-width: 500px) {
  form {
    width: 100%;
    margin: 0;
    justify-content: center;
    align-items: center;
  }

  .container {
    display: flex;
    justify-content: center;
    align-self: center;
  }

  .page-container {
    display: flex;
    justify-content: center;
  }

  .form-text {
    margin: 15px;
    max-width: 400px;
  }

  .page-container {
    display: flex;
    justify-content: center;
  }

  .input-container {
    max-width: 300px;
    align-self: center;
    flex-direction: column;
  }

  .checkbox-container {
    max-width: 300px;
    margin-left: 10px;
  }

  .button {
    max-width: 300px;
    align-self: center;
  }

  .input-fields {
    display: flex;
    flex-direction: column;
  }

  .input-field {
    width: 100%;
  }
}
