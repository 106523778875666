.main {
  background-color: transparent;
  padding: 24px;
  border: 1px solid rgba(229, 231, 235, 255);
  border-radius: 15px;
  z-index: 5;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.title {
  text-align: left;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #111827;
  line-height: 30px;
  margin-top: 0;
  margin-bottom: 24px;
}

.info {
  display: flex;
  flex-direction: column;
}
