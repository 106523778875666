body {
    height: 100vh;
}
.page {
    background-image: url("../../assets//images/404_Background.jpg");
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
}
.title{
    font-family: 'Akrobat';
font-style: normal;
font-weight: 700;
font-size: 200px;
line-height: 250px;
color: rgba(55, 65, 81, 1);
margin: 0;
}
.paragraph{
    font-family: 'Akrobat';
font-style: normal;
font-weight: 700;
font-size: 28px;
line-height: 35px;
color: #111827;
margin-top: 0;
margin-bottom: 40px;
}
.anchor{
    font-family: 'Akrobat';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 25px;
color: rgba(12, 64, 118, 1);

}
.logo{
    position: absolute;
    top: 40px;
    right: 46.8%;
}