* {
  box-sizing: border-box;
  font-family: "Akrobat", sans-serif;
}

.text {
  border: none;
  background-color: transparent;
  width: 100%;
  cursor: pointer;
}

.main {
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  text-align: left;
  position: relative;
}

.label {
  display: block;
  font-size: 14px;
  min-height: 19px;
  color: #0c4076;
  transition: all 0.2s;
  overflow: hidden;
  opacity: 100;
}

.unvisible {
  opacity: 0;
}

.placeholder {
  font-weight: 500;
  color: rgb(117, 117, 117);
}

.button {
  display: flex;
  background-color: transparent;
  justify-content: space-between;
  align-items: stretch;
  text-align: left;
  color: #111827;
  font-size: 20px;
  font-weight: 500;
  padding: 0;
  border: none;
  width: 100%;
  padding-bottom: 5px;
  border-bottom: 1px solid #9ca3af;
}

.button:hover {
  cursor: pointer;
}

.button:active {
  background-color: rgb(57, 100, 145, 0.3);
}

.input {
  background-color: transparent;
  color: #111827;
  font-size: 20px;
  font-weight: 500;
  transition: all 0.2s;
  padding: 0;
  width: 100%;
}

.icon {
  margin: 0 5px;
  padding-bottom: 2px;
  display: flex;
  align-items: flex-end;
}

.input-container-error {
  min-height: 17px;
  font-size: 14px;
  line-height: 17px;
  margin-top: 8px;
}

.error {
  color: #e01b1b;
}

.error-border {
  border-bottom: 1px solid red;
}

.svg {
  fill: black;
  width: 20px;
  height: 20px;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}