.toolbar {
  max-width: 1200px;
  margin: 0 auto;
  padding: 24px;
  width: 100%;
}

.page-container {
  box-sizing: border-box;
}

.content-container {
  min-height: calc(100vh - 392px);
  max-width: 1200px;
  margin: 0 auto;
  padding: 24px;
  display: flex;
  flex-direction: column;
  --wp--style--block-gap: 1.5rem;
}

.subtitle {
  margin: 0 0 16px;
  font-size: 20px;
  line-height: 25px;
}

.para {
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: #6b7280;
  margin-bottom: 16px;
}

.list {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.list-item {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: #6b7280;
  margin: 0;
}

.bold {
  font-weight: 700;
}

.underline {
  text-decoration: underline;
}
.table {
  box-sizing: border-box;
  border-collapse: separate;
  text-indent: initial;
  border-spacing: 2px;
  margin: 35px 0;
  font-size: 18px;
}
.head {
  font-weight: bold;
}
.border {
  border: 1px solid black;
  padding: 15px;
}
