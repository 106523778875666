.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  color: rgba(107, 114, 128, 1);
  background-color: rgba(249, 250, 251, 255);
}

.toolbar {
  max-width: 1200px;
  margin: 0 auto;
  padding: 24px;
  width: 100%;
}

.form-title {
  font-size: 28px;
  font-weight: 300;
  font-family: "Akrobat Bold";
  color: #111827;
  margin: 0;
  align-self: center;
  padding-bottom: 15px;
}

.form-text {
  margin: 0;
  font-weight: 500;
  font-size: 20px;
  padding: 15px;
}

.h3 {
  color: #111827;
}

.left-container {
  font-size: 20px;
  float: left;
  color: #6b7280;
  text-align: start;
  width: 28vw;
}

.right-container {
  font-size: 20px;
  float: right;
  color: #4b5563;
  font-weight: 600;
  text-align: start;
  width: 28vw;
}

.text-container {
  flex-direction: row;
  width: 56vw;
  padding-top: 15px;
}

.upper-container {
  border-bottom: 1.5px solid #4b5563;
  padding-bottom: 30px;
}

.wrap-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 56vw;
}
