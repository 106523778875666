.card {
  width: fit-content;
  margin: 3rem auto;
  background-color: white;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(189, 147, 147, 0.25);
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
  font-weight: bold;
}
