.App {
  text-align: center;
}
.datepicker {
  display: none;
}
.datepicker.active {
  display: block;
}
.datepicker-dropdown {
  left: 0;
  padding-top: 4px;
  position: absolute;
  top: 0;
  z-index: 20;
}
.datepicker-dropdown.datepicker-orient-top {
  padding-bottom: 4px;
  padding-top: 0;
}
.datepicker-picker {
  background-color: #fff;
  border-radius: 4px;
  display: inline-block;
}
.datepicker-dropdown .datepicker-picker {
  box-shadow: 0 2px 3px hsla(0, 0%, 4%, 0.1), 0 0 0 1px hsla(0, 0%, 4%, 0.1);
}
.datepicker-picker span {
  -webkit-touch-callout: none;
  border: 0;
  border-radius: 4px;
  cursor: default;
  display: block;
  flex: 1;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.datepicker-main {
  padding: 2px;
}
.datepicker-footer {
  background-color: #f5f5f5;
  box-shadow: inset 0 1px 1px hsla(0, 0%, 4%, 0.1);
}
.datepicker-controls,
.datepicker-grid,
.datepicker-view,
.datepicker-view .days-of-week {
  display: flex;
}
.datepicker-grid {
  flex-wrap: wrap;
}
.datepicker-view .days .datepicker-cell,
.datepicker-view .dow {
  flex-basis: 14.2857142857%;
}
.datepicker-view.datepicker-grid .datepicker-cell {
  flex-basis: 25%;
}
.datepicker-cell,
.datepicker-view .week {
  height: 2.25rem;
  line-height: 2.25rem;
}
.datepicker-title {
  background-color: #f5f5f5;
  box-shadow: inset 0 -1px 1px hsla(0, 0%, 4%, 0.1);
  font-weight: 700;
  padding: 0.375rem 0.75rem;
  text-align: center;
}
.datepicker-header .datepicker-controls {
  padding: 2px 2px 0;
}
.datepicker-controls .button {
  align-items: center;
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  box-shadow: none;
  color: #363636;
  cursor: pointer;
  display: inline-flex;
  font-size: 1rem;
  height: 2.25em;
  justify-content: center;
  line-height: 1.5;
  margin: 0;
  padding: calc(0.375em - 1px) 0.75em;
  position: relative;
  text-align: center;
  vertical-align: top;
  white-space: nowrap;
}
.datepicker-controls .button:active,
.datepicker-controls .button:focus {
  outline: none;
}
.datepicker-controls .button:hover {
  border-color: #b5b5b5;
  color: #363636;
}
.datepicker-controls .button:focus {
  border-color: #3273dc;
  color: #363636;
}
.datepicker-controls .button:focus:not(:active) {
  box-shadow: 0 0 0 0.125em rgba(50, 115, 220, 0.25);
}
.datepicker-controls .button:active {
  border-color: #4a4a4a;
  color: #363636;
}
.datepicker-controls .button[disabled] {
  cursor: not-allowed;
}
.datepicker-header .datepicker-controls .button {
  border-color: transparent;
  font-weight: 700;
}
.datepicker-header .datepicker-controls .button:hover {
  background-color: #f9f9f9;
}
.datepicker-header .datepicker-controls .button:focus:not(:active) {
  box-shadow: 0 0 0 0.125em hsla(0, 0%, 100%, 0.25);
}
.datepicker-header .datepicker-controls .button:active {
  background-color: #f2f2f2;
}
.datepicker-header .datepicker-controls .button[disabled] {
  box-shadow: none;
}
.datepicker-footer .datepicker-controls .button {
  border-radius: 2px;
  font-size: 0.75rem;
  margin: calc(0.375rem - 1px) 0.375rem;
  width: 100%;
}
.datepicker-controls .view-switch {
  flex: auto;
}
.datepicker-controls .next-btn,
.datepicker-controls .prev-btn {
  padding-left: 0.375rem;
  padding-right: 0.375rem;
  width: 2.25rem;
}
.datepicker-controls .next-btn.disabled,
.datepicker-controls .prev-btn.disabled {
  visibility: hidden;
}
.datepicker-view .dow {
  font-size: 0.875rem;
  font-weight: 700;
  height: 1.5rem;
  line-height: 1.5rem;
}
.datepicker-view .week {
  color: #b5b5b5;
  font-size: 0.75rem;
  width: 2.25rem;
}
@media (max-width: 22.5rem) {
  .datepicker-view .week {
    width: 1.96875rem;
  }
}
.datepicker-grid {
  width: 15.75rem;
}
@media (max-width: 22.5rem) {
  .calendar-weeks + .days .datepicker-grid {
    width: 13.78125rem;
  }
}
.datepicker-cell:not(.disabled):hover {
  background-color: #f9f9f9;
  cursor: pointer;
}
.datepicker-cell.focused:not(.selected) {
  background-color: #e8e8e8;
}
.datepicker-cell.selected,
.datepicker-cell.selected:hover {
  background-color: #3273dc;
  color: #fff;
  font-weight: 600;
}
.datepicker-cell.disabled {
  color: #dbdbdb;
}
.datepicker-cell.next:not(.disabled),
.datepicker-cell.prev:not(.disabled) {
  color: #7a7a7a;
}
.datepicker-cell.next.selected,
.datepicker-cell.prev.selected {
  color: #e6e6e6;
}
.datepicker-cell.highlighted:not(.selected):not(.range):not(.today) {
  background-color: #f5f5f5;
  border-radius: 0;
}
.datepicker-cell.highlighted:not(.selected):not(.range):not(.today):not(.disabled):hover {
  background-color: #eee;
}
.datepicker-cell.highlighted:not(.selected):not(.range):not(.today).focused {
  background-color: #e8e8e8;
}
.datepicker-cell.today:not(.selected) {
  background-color: #00d1b2;
}
.datepicker-cell.today:not(.selected):not(.disabled) {
  color: #fff;
}
.datepicker-cell.today.focused:not(.selected) {
  background-color: #00c4a7;
}
.datepicker-cell.range-end:not(.selected),
.datepicker-cell.range-start:not(.selected) {
  background-color: #b5b5b5;
  color: #fff;
}
.datepicker-cell.range-end.focused:not(.selected),
.datepicker-cell.range-start.focused:not(.selected) {
  background-color: #afafaf;
}
.datepicker-cell.range-start {
  border-radius: 4px 0 0 4px;
}
.datepicker-cell.range-end {
  border-radius: 0 4px 4px 0;
}
.datepicker-cell.range {
  background-color: #dbdbdb;
  border-radius: 0;
}
.datepicker-cell.range:not(.disabled):not(.focused):not(.today):hover {
  background-color: #d5d5d5;
}
.datepicker-cell.range.disabled {
  color: #c2c2c2;
}
.datepicker-cell.range.focused {
  background-color: #cfcfcf;
}
.datepicker-view.datepicker-grid .datepicker-cell {
  height: 4.5rem;
  line-height: 4.5rem;
}
.datepicker-input.in-edit {
  border-color: #2366d1;
}
.datepicker-input.in-edit:active,
.datepicker-input.in-edit:focus {
  box-shadow: 0 0 0.25em 0.25em rgba(35, 102, 209, 0.2);
}
