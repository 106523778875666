
.main {
  max-width: 480px;
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  text-align: left;
  position: relative;
}

.label {
  display: block;
  font-size: 14px;
  min-height: 19px;
  color: #0c4076;
  transition: all 0.2s;
  overflow: hidden;
  opacity: 100;
}

.input {
  display: flex;
  flex-direction: row;
}

.input-container-error {
  font-size: 14px;
  min-height: 17px;
  margin-top: 8px;
}

.error {
  color: #e01b1b;
}
