.form {
  font-family: "Akrobat";
  margin: 15px;
  max-width: 500px;
  color: rgba(107, 114, 128, 1);
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: 28px;
  font-weight: 300;
  font-family: "Akrobat Bold";
  color: #111827;
  margin: 0;
  align-self: center;
}

.text {
  margin: 0;
  font-weight: 500;
  font-size: 20px;
  padding: 15px;
}

.input-container {
  display: flex;
  align-items: center;
  margin: 5px;
  position: relative;
  max-width: 480px;
  width: 100%;
}

.button {
  width: 480px;
}

.input-container-error {
  font-size: 20px;
  line-height: 17px;
  margin-top: 8px;
  text-align: left;
}

.error {
  color: #e01b1b;
}

@media (max-width: 1200px) {
  .page-container {
    max-width: 310px;
    align-self: center;
  }

  .button {
    max-width: 310px;
    align-self: center;
  }
}
