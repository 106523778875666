.form {
  display: grid;
  align-items: center;
  font-family: "Akrobat";
  justify-content: center;
  margin: 15px;
  max-width: 500px;
  color: rgba(107, 114, 128, 1);
}

.page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.form-title {
  font-size: 28px;
  font-weight: 300;
  font-family: "Akrobat Bold";
  color: #111827;
  margin: 0;
  align-self: center;
}

.form-text {
  margin: 0;
  font-weight: 500;
  font-size: 20px;
  padding: 15px;
}

.primary-link {
  color: rgba(12, 64, 118, 1);
  text-decoration: none;
}

.form-text-header {
  padding: 0;
  color: black;
  margin-bottom: 6px;
  flex-basis: 100%;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
}

.text-right {
  display: flex;
  justify-content: right;
  margin-bottom: 9px;
}

.text-right-sub {
  display: inline;
  padding: 0;
}

.text-bottom {
  align-self: center;
  font-size: 20px;
  color: black;
}

.form-text-hover:hover {
  color: rgba(17, 24, 39, 1);
  text-decoration: underline;
  cursor: pointer;
}

.input-container {
  display: flex;
  align-items: center;
  position: relative;
  max-width: 500px;
  width: 100%;
}

.input-container-error {
  font-size: 20px;
  line-height: 17px;
  margin-top: 8px;
  text-align: left;
}

.error {
  color: #e01b1b;
}

@media (max-width: 768px) {
  form {
    width: 100%;
    justify-content: center;
  }

  .form-text {
    margin: 15px;
    max-width: 600px;
  }

  .input-container {
    align-self: center;
  }
}

@media screen and (max-width: 500px) {
  form {
    width: 100%;
    margin: 0;
    justify-content: center;
  }

  .form-text {
    margin: 15px;
    max-width: 400px;
  }

  .page-container {
    display: flex;
    justify-content: center;
  }

  .input-container {
    align-self: center;
  }
}