.loading {
  position: relative;
  padding: 0;
  margin: 0;
  border-radius: 50%;
  animation: loading 1.5s infinite linear;
}
@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.primary {
  border: 4px solid rgba(194, 210, 226, 1);
  border-top: solid 4px rgba(12, 64, 118, 1);
}
.transparent {
  border: 4px solid rgba(255, 255, 255, 0.5);
  border-top: solid 4px rgba(255, 255, 255, 1);
}
.small {
  width: 24px;
  height: 24px;
}
.big {
  width: 40px;
  height: 40px;
}
.absolute {
  position: absolute;
}