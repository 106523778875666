.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: rgba(107, 114, 128, 1);
}

.form-title {
  font-size: 28px;
  font-weight: 300;
  font-family: "Akrobat Bold";
  color: #111827;
  margin: 0;
  align-self: center;
}

.form-text {
  margin: 0;
  font-weight: 500;
  font-size: 20px;
  padding: 15px;
}
