* {
  box-sizing: border-box;
}

.dialog {
  display: flex;
  flex-direction: column;
  text-align: center;
  min-width: 400px;
  width: fit-content;
  background-color: white;
  gap: 16px;
  padding: 48px 24px 24px;
  border-radius: 8px;
  box-shadow: 0 5px 50px rgba(0, 0, 0, 0.15);
  z-index: 100;
  height: 100%;
}

.title {
  color: #111827;
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;
  text-align: center;
}

.text {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #6b7280;
  text-align: center;
}

.primary {
  background: rgba(26, 159, 217, 1);
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
  padding: 0;
}

.close-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}